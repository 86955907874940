const textboxlookup = {
	init: function () {
		const tbl = document.querySelectorAll('.textboxlookup');

		if (tbl.length === 0) {
			// Check for existance of .textboxlookup and return if none on the page
			return;
		} else {
			Array.prototype.forEach.call(tbl, function (tblContainer) {
				const input = tblContainer.querySelector('.textboxlookup__input');
				const btn = tblContainer.querySelector('.textboxlookup__button');
				if (input) {
					textboxlookup.handleInput(input, tblContainer);
				}
				if (btn) {
					textboxlookup.handleButton(btn, tblContainer, input);
				}
			});
		}
	},

	handleInput: function (input, tblContainer) {
		input.addEventListener('keypress', function (event) {
			textboxlookup.validate(event, input, tblContainer);
		});
	},

	handleButton: function (btn, tblContainer, input) {
		btn.addEventListener('click', function (event) {
			event.preventDefault();
			textboxlookup.postToLocation(event, input, tblContainer);
		});
	},

	validate: function (event, input, tblContainer) {
		let isValid = false;
		const keyed = event.keyCode || event.which || event.key;

		// Regex testing for aplha numeric inputs
		const regex = /^[\w\s]+$/;
		isValid = regex.test(String.fromCharCode(keyed));

		if (!isValid) {
			event.preventDefault();
			return false;
		}

		if (input.classList.contains('insurance-group-registration-input')) return;

		if (event.keyCode === 13 || event.key === 'Enter') {
			event.preventDefault();
			if (isValid) {
				textboxlookup.postToLocation(event, input, tblContainer);
			}
		}
		return isValid;
	},

	postToLocation: function (event, input, tblContainer) {
		event.preventDefault();
		let url = '',
			param = '';

		//Using Window.location to redirect to url as the form post element is overriding the URL params
		if (input.value === '') {
			url = tblContainer.querySelector('#textboxlookup__urlWithoutValue').innerHTML;
			window.location = url;
		} else {
			const textInput = input.value.replace(/\s/g, '');
			const val = encodeURIComponent(textInput);
			url = tblContainer.querySelector('#textboxlookup__urlWithValue').innerHTML;
			param = tblContainer.querySelector('#textboxlookup__urlParamValue').innerHTML;

			const queryStringSeparator = url.indexOf('?') > -1 ? '&' : '?';
			window.location = `${url}${queryStringSeparator}${param}=${val}`;
		}
	},
};

document.addEventListener('DOMContentLoaded', function () {
	textboxlookup.init();
});
