const jumpLinks = {
	handleJumpLinkClicked: e => {
		e.preventDefault();

		const globalHeader = document.querySelector('.cnf-header'),
			globalHeaderHeight = globalHeader ? globalHeader.offsetHeight : 0; //DEFAULT IN CASE HEADER NOT ON PAGE FOR SOME REASON

		//IF DATA-LOCATION EXISTS, USE THAT FOR POSITIONING (COVERS FLOATING-TABLE.JS)
		const targetId = e.target.getAttribute('data-location')
				? e.target.getAttribute('data-location')
				: e.target.getAttribute('href').substring(1),
			targetElement = document.getElementById(targetId),
			scrollMargin = 20; // ADD MARGIN TO SCROLL FOR SOME PADDING

		if (targetElement) {
			const targetScrollTop =
				targetElement.getBoundingClientRect().top + window.scrollY - (globalHeaderHeight + scrollMargin);
			window.scrollTo({ top: targetScrollTop, behavior: 'smooth' });
		}
	},
};

document.addEventListener('DOMContentLoaded', function () {
	const jumpLinkNodes = document.querySelectorAll('.mod-jump-links a');
	if (!jumpLinkNodes) return;

	jumpLinkNodes.forEach(link => {
		link.addEventListener('click', jumpLinks.handleJumpLinkClicked);
	});
});
