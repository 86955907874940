const footerNav = (function () {
	let content;
	function init() {
		if (document.querySelectorAll('.accordion-nav-container')) {
			content = document.querySelectorAll('.accordion-nav-container');
			content.forEach(function (section) {
				const title = section.querySelector('.accordion-nav__titles--primary');
				title.addEventListener('click', function (event) {
					if (!section.classList.contains('accordion-open')) {
						event.preventDefault();
						resetAccordion();
						activateAccordion(section);
					} else {
						resetAccordion();
					}
				});
			});
		}
	}

	function activateAccordion(section) {
		section.classList.toggle('accordion-open');
	}
	function resetAccordion() {
		content.forEach(function (section) {
			section.classList.remove('accordion-open');
		});
	}
	return {
		init: init,
	};
})();
document.addEventListener('DOMContentLoaded', function () {
	footerNav.init();
});
