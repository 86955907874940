class ShowMoreCards {
	constructor(cards, button, showMoreButtonText) {
		this.cardsInitiallyDisplayed = 0;
		this.cardsToggledVisible = false;
		this.showMoreButtonText = showMoreButtonText;

		if (!button || !cards.length) return;

		this.init(cards, button);
	}

	init = (cards, button) => {
		let hasHiddenCards = false;
		let totalVisibleCards = 0;

		//COUNT TOTAL NUMBER OF VISIBLE CARDS TO BE USED WHEN TOGGLE HIDDEN
		cards.forEach(card => {
			if (card.classList.contains('display-none')) {
				hasHiddenCards = true;
				totalVisibleCards++;
			}
		});

		this.cardsInitiallyDisplayed = totalVisibleCards;

		//IF CARDS ARE SET TO HIDDEN, SET UP BUTTON FOR TOGGLE, ELSE HIDE THE BUTTON
		if (hasHiddenCards) {
			button.addEventListener('click', e => this.toggleCards(e, button, cards));
		} else {
			this.hideShowMoreButton();
		}
	};

	toggleCards = (e, button, cards) => {
		//IF CARDS ARE TOGGLED VISIBLE/INVISIBLE, HIDE/SHOW CARDS
		e.preventDefault();
		if (!this.cardsToggledVisible) {
			this.displayCards(cards);
			this.toggleButtonType(button, 'minus-link', 'Show less');
			this.cardsToggledVisible = true;
		} else {
			this.hideCards(cards);
			this.toggleButtonType(button, 'plus-link', this.showMoreButtonText);
			this.cardsToggledVisible = false;
		}
	};

	hideCards = cards => {
		//HIDE CARDS
		const firstHiddenCard = cards.length - this.cardsInitiallyDisplayed;
		cards.forEach((card, index) => {
			if (index >= firstHiddenCard) {
				card.classList.add('display-none');
			}
		});
	};

	displayCards = cards => {
		//SHOW CARDS
		cards.forEach(card => {
			if (card.classList.contains('display-none')) {
				card.classList.remove('display-none');
			}
		});
	};

	toggleButtonType = (button, newButtonClass, newButtonText) => {
		//FUNCTION TO CHANGE BUTTON CLASS + UPDATE BUTTON TEXT

		const currentButtonClass = [...button.classList].find(
			cList => cList.includes('plus-link') || cList.includes('minus-link')
		);

		const currentTitleClass = `${currentButtonClass}__title`;
		const buttonTitleElement = button.querySelector(`.${currentTitleClass}`);

		button.classList.remove(`${currentButtonClass}`);
		button.classList.add(`${newButtonClass}`);

		buttonTitleElement.classList.remove(`${currentTitleClass}`);
		buttonTitleElement.classList.add(`${newButtonClass}__title`);
		buttonTitleElement.innerText = `${newButtonText}`;
	};

	hideShowMoreButton = button => {
		button.classList.remove('display-none');
	};
}

document.addEventListener('DOMContentLoaded', function () {
	const showMoreCardSection = document.querySelectorAll('.mod-show-more-cards');

	showMoreCardSection.forEach(section => {
		const cards = section.querySelectorAll('.mod-flex-cards__card');
		const showMoreButton = section.querySelector('.plus-link');
		const showMoreButtonText = section.querySelector('.plus-link__title').innerText;

		new ShowMoreCards(cards, showMoreButton, showMoreButtonText);
	});
});
