const backToTop = (function () {
	function init() {
		const bttLink = document.querySelector('.btt-arrow');
		if (typeof bttLink !== 'undefined' && bttLink !== null) {
			bttLink.addEventListener('click', function (e) {
				e.preventDefault();
				window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth',
				});
			});
		}
	}

	return {
		init: init,
	};
})();

document.addEventListener('DOMContentLoaded', function () {
	backToTop.init();
});
