const subToggle = {
	init: (e, toggleLink, toggleContent) => {
		e.preventDefault();

		const toggleOpen = toggleLink.classList.contains('open') ? true : false;

		if (toggleOpen) {
			//CLOSE TOGGLE
			toggleLink.classList.remove('open');
			toggleContent.style.maxHeight = '0px';
			toggleContent.style.marginBottom = '0px';
		} else {
			//OPEN TOGGLE
			toggleLink.classList.add('open');
			toggleContent.style.marginBottom = '1rem';
			toggleContent.style.maxHeight = `${toggleContent.scrollHeight}px`;
		}
	},
};

document.addEventListener('DOMContentLoaded', function () {
	const toggleLinks = document.querySelectorAll('.toggle-link');

	toggleLinks.forEach(toggleLink => {
		const controllId = toggleLink.getAttribute('aria-controls');
		const toggleContent = document.getElementById(`${controllId}`);
		const animationTime = 0.3;

		//TECH-DEBT -> MOVE STYLING TO CSS CLASSES
		toggleContent.style.display = 'block';
		toggleContent.style.overflow = 'hidden';
		toggleContent.style.maxHeight = '0px';
		toggleContent.style.transition = `all ${animationTime}s ease`; //TRANSITION
		toggleContent.style.marginBottom = '0px';

		toggleLink.addEventListener('click', e => subToggle.init(e, toggleLink, toggleContent));
	});
});
