class MiniCarousel {
	constructor(carouselElement) {
		this.carousel = carouselElement;
		this.slides = this.carousel.querySelectorAll('.mod-mini-carousel__slide');
		this.nextButton = this.carousel.querySelector('.mod-mini-carousel__buttons--next');
		this.previousButton = this.carousel.querySelector('.mod-mini-carousel__buttons--previous');
		this.activeIndex = 0; // Start index at 0
		this.initSlides();
		this.setupNavigation();
		this.setupTouchEvents();
	}

	// Initialize slides' visibility and button state
	initSlides() {
		this.slides.forEach((slide, index) => {
			if (index === 0) {
				slide.className = 'mod-mini-carousel__slide mod-mini-carousel__slide--visible';
			} else {
				slide.className = 'mod-mini-carousel__slide mod-mini-carousel__slide--hidden';
			}
		});
		this.updateButtonsState();
	}

	// Setup click event listeners for navigation buttons
	setupNavigation() {
		this.nextButton.addEventListener('click', () => this.changeSlide('next'));
		this.previousButton.addEventListener('click', () => this.changeSlide('prev'));
	}

	// Change the active slide based on direction ('next' or 'prev')
	changeSlide(direction) {
		const maxIndex = this.slides.length - 1;
		if (direction === 'next') {
			if (this.activeIndex < maxIndex) {
				this.activeIndex++;
			}
		} else if (direction === 'prev') {
			if (this.activeIndex > 0) {
				this.activeIndex--;
			}
		}

		this.updateSlideVisibility();
		this.updateButtonsState();
	}

	// Update slide visibility based on the active index
	updateSlideVisibility() {
		this.slides.forEach((slide, index) => {
			if (index === this.activeIndex) {
				slide.classList.add('mod-mini-carousel__slide--visible');
				slide.classList.remove('mod-mini-carousel__slide--hidden');
			} else {
				slide.classList.remove('mod-mini-carousel__slide--visible');
				slide.classList.add('mod-mini-carousel__slide--hidden');
			}
		});
	}

	// Enable or disable navigation buttons based on the current slide index
	updateButtonsState() {
		if (this.activeIndex === 0) {
			this.previousButton.disabled = true;
			this.previousButton.setAttribute('aria-disabled', true);
		} else {
			this.previousButton.disabled = false;
			this.previousButton.removeAttribute('aria-disabled');
		}

		if (this.activeIndex === this.slides.length - 1) {
			this.nextButton.disabled = true;
			this.nextButton.setAttribute('aria-disabled', true);
		} else {
			this.nextButton.disabled = false;
			this.nextButton.removeAttribute('aria-disabled');
		}
	}

	// Setup touch event listeners for swipe functionality
	setupTouchEvents() {
		let startX;
		this.carousel.addEventListener('touchstart', event => {
			startX = event.touches[0].clientX; // Record the start X position on touch start
		});

		this.carousel.addEventListener('touchend', event => {
			const endX = event.changedTouches[0].clientX; // Get the end X position on touch end
			if (Math.abs(startX - endX) > 50) {
				// Check if the swipe was significant
				const direction = startX > endX ? 'next' : 'prev';
				this.changeSlide(direction);
			}
		});
	}
}

document.addEventListener('DOMContentLoaded', () => {
	document.querySelectorAll('.mod-mini-carousel').forEach(carouselElement => {
		new MiniCarousel(carouselElement); // Create a new carousel instance for each carousel element
	});
});
