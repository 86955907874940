const multiCta = (function () {
	function init() {
		setupClickHandlers();
	}

	function setupClickHandlers() {
		const multiCtaLists = document.querySelectorAll('.field-group--multi-cta');

		if (multiCtaLists) {
			Array.prototype.forEach.call(multiCtaLists, function (multiCtaList) {
				const inputs = multiCtaList.querySelectorAll('input[type="radio"]');
				const button = multiCtaList.querySelector('.btn.cta-action');
				const errorMsg = multiCtaList.querySelector('.field-validation-error');

				Array.prototype.forEach.call(inputs, function (input) {
					input.addEventListener('change', function (e) {
						const urlVal = e.target.getAttribute('data-ctaUrl');
						removeErrorClass(errorMsg);
						updateButtonUrl(button, urlVal);
					});
				});

				button.addEventListener('click', function (e) {
					if (!multiCtaList.querySelector('input[type="radio"]:checked')) {
						e.preventDefault();
						addErrorClass(errorMsg);
						disableButton(button);
					}
				});
			});
		}
	}

	function removeErrorClass(el) {
		el.style.display = 'none';
	}

	function addErrorClass(el) {
		el.style.display = 'block';
	}

	function disableButton(button) {
		button.style.pointerEvents = 'none';
	}

	function updateButtonUrl(button, urlVal) {
		button.setAttribute('href', urlVal);
		button.style.pointerEvents = 'all';
	}

	return {
		init: init,
	};
})();

document.addEventListener('DOMContentLoaded', function () {
	multiCta.init();
});
