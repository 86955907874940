const faqPage = {
	selects: document.querySelectorAll('[data-id="help-center--select"]'),

	init: function () {
		Array.prototype.forEach.call(faqPage.selects, function (select) {
			faqPage.handleSelectOnChange(select);

			select.addEventListener('change', function (e) {
				faqPage.handleSelectOnChange(e.target);
			});
		});
	},

	handleSelectOnChange: function (select) {
		//SET THE SELECTED GROUP OF ACCORDIONS DEPENDING ON THE SELECT INPUT VALUE
		const helpcenter = select.closest('[data-id="help-center"]');
		const accordionGroups = helpcenter.querySelectorAll('.mod-accordion');
		const selectedAccordionGroup = accordionGroups[select.selectedIndex];

		Array.prototype.forEach.call(accordionGroups, function (group) {
			const accordionRow = group.closest('.accordion') || group.closest('.row.row--gutters-m');
			if (group !== selectedAccordionGroup) {
				accordionRow.style.display = 'none';
			} else {
				accordionRow.style.display = 'block';
			}
		});
	},
};

document.addEventListener('DOMContentLoaded', function () {
	const faqSelectExists = document.querySelector('[data-id="help-center"]');
	if (faqSelectExists) {
		faqPage.init();
	}
});
