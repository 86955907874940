const formInputValidationMessages = function (input, otherInputClicked) {
	//CHECKS WHAT TYPE OF FIELD AND PASSES BACK THE ERROR MESSAGE & FIELD ID

	if (input.name === 'subject_select') {
		if (otherInputClicked) {
			if (input.value === '' || input.value.trim() === '') {
				return { message: input.dataset.inputInvalid, input: input.id };
			}
		}
		if (input.value === '' || input.value.trim() === '') {
			return { message: input.dataset.inputSkipped, input: input.id };
		}
	}

	if (input.name === 'issues_select') {
		if (input.value === '' || input.value.trim() === '' || input.value === 'Select an issue') {
			return { message: input.dataset.inputSkipped, input: input.id };
		}
	}

	if (input.name === 'email address') {
		//SANITY CHECK EMAIL FORMAT
		const re = /.+\@.+\..+/;
		if (!input.value || !re.test(input.value) || input.value.trim() === '') {
			return { message: input.dataset.inputInvalid, input: input.id };
		}
	}

	if (input.name === 'first name') {
		if (input.value.trim() === '') {
			return { message: input.dataset.inputSkipped, input: input.id };
		}
		if (input.value.length > 20 || input.value.length < 2) {
			return { message: input.dataset.inputInvalid, input: input.id };
		}
	}

	if (input.name === 'last name') {
		if (input.value.trim() === '') {
			return { message: input.dataset.inputSkipped, input: input.id };
		}
		if (input.value.length > 30 || input.value.length < 2) {
			return { message: input.dataset.inputInvalid, input: input.id };
		}
	}

	if (input.id.substring(0, 3) === 'dob') {
		if (input.value.trim() === '') {
			return { message: input.dataset.inputSkipped, input: input.id, priority: 0 };
		}

		if (Number.isNaN(Number(input.value))) {
			return { message: input.dataset.inputInvalid, input: input.id, priority: 2 };
		}

		if (input.name === 'day') {
			const month = document.querySelector('#dob_month').value;
			const year = document.querySelector('#dob_year').value;
			const lastDayOfMonth = new Date(year, month, 0).getDate();

			if (month || year !== null) {
				if (parseInt(input.value, 10) > lastDayOfMonth) {
					//IF DAY ENTERED NOT IN MONTH
					return { message: input.dataset.inputInvalidDay, input: input.id, priority: 4 };
				}
			}
		}

		if (input.name === 'month') {
			if (input.value > 12 || input.value === 0) {
				return { message: input.dataset.inputInvalidMonth, input: input.id, priority: 5 };
			}
		}

		if (input.name === 'year') {
			const day = document.querySelector('#dob_day');
			const month = document.querySelector('#dob_month');
			const currentDay = new Date();
			const currentYear = currentDay.getFullYear();
			const enteredDay = new Date(`${month.value}/${day.value}/${input.value}`);

			if (Date.parse(currentDay) < Date.parse(enteredDay)) {
				return {
					message: input.dataset.inputInvalidYear_past,
					input: [day.id, month.id, input.id],
					priority: 1,
				};
			}

			if (input.value.length < 4 || input.value.length > 4) {
				return { message: input.dataset.inputInvalidYear_format, input: input.id, priority: 3 };
			}

			if (currentYear - 130 > input.value) {
				return { message: input.dataset.inputInvalidYear_age, input: input.id, priority: 6 };
			}
		}
	}

	if (input.name === 'postcode') {
		const inputWithoutSpaces = input.value.replace(/\s/g, '');
		//POSTCODE REGEX FROM UK GOV
		const re =
			/([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))[0-9][A-Za-z]{2})/;
		if (input.value.trim() === '') {
			return { message: input.dataset.inputSkipped, input: input.id };
		}
		if (!re.test(inputWithoutSpaces)) {
			return { message: input.dataset.inputInvalid, input: input.id };
		}
	}

	if (input.id === 'phone_consent-yes') {
		var noInput = document.querySelector('#phone_consent-no');
		if (!input.checked && !noInput.checked) {
			return { message: input.dataset.inputSkipped, input: input.id, priority: 0 };
		}
	}

	if (input.id === 'phone_consent-no') {
		var yesInput = document.querySelector('#phone_consent-yes');
		if (!input.checked && !yesInput.checked) {
			return { message: input.dataset.inputSkipped, input: input.id, priority: 99 };
		}
	}

	if (input.name === 'phone number') {
		if (input.value.trim() === '') {
			return { message: input.dataset.inputSkipped, input: input.id };
		}

		if (input.value.trim().length < 10 || input.value.trim().length > 14) {
			return { message: input.dataset.inputInvalid, input: input.id };
		}
	}

	if (input.id === 'insurance_bought-yes') {
		var noInput = document.querySelector('#insurance_bought-no');
		if (!input.checked && !noInput.checked) {
			return { message: input.dataset.inputSkipped, input: input.id, priority: 0 };
		}
	}

	if (input.id === 'insurance_bought-no') {
		var yesInput = document.querySelector('#insurance_bought-yes');
		if (!input.checked && !yesInput.checked) {
			return { message: input.dataset.inputSkipped, input: input.id, priority: 99 };
		}
	}

	if (input.id === 'message_subject') {
		if (input.value.trim() === '') {
			return { message: input.dataset.inputSkipped, input: input.id };
		}

		if (input.value.length > 255) {
			return { message: input.dataset.inputInvalid, input: input.id };
		}
	}

	if (input.id === 'message_content') {
		if (input.value.trim() === '') {
			return { message: input.dataset.inputSkipped, input: input.id };
		}

		if (input.value.length > 32000) {
			return { message: input.dataset.inputInvalid, input: input.id };
		}
	}
};

const keyInputs = function (e, input) {
	const code = e.keyCode || e.which;

	if (input.id.substring(0, 3) === 'dob') {
		if (code === 8 || code === 13 || code === 9 || code === 27 || code === 110) {
			return;
		}

		if (isNaN(parseInt(String.fromCharCode(code), 10))) {
			e.preventDefault();
			return false;
		}
	}

	if (input.id === 'phone_number') {
		if (
			code === 8 ||
			code === 13 ||
			code === 9 ||
			code === 27 ||
			code === 32 ||
			code === 188 ||
			code === 43 ||
			code === 187
		) {
			return;
		}

		if (isNaN(parseInt(String.fromCharCode(code), 10))) {
			e.preventDefault();
			return false;
		}
	}
};

var formValidator = {
	form: document.getElementById('cnfsd-contact-form'),
	totalErrors: 0,
	validateInput: formInputValidationMessages,
	handleKeyInputs: keyInputs,
	formInputs: [],

	initialise: function () {
		formValidator.formInputs = formValidator.form.querySelectorAll('.field-group');
		formValidator.validateOnFieldEntry();
	},

	validateOnFieldEntry: function () {
		//ADDS EVENT LISTENER TO EACH INPUT TO REMOVE ERRORS WHEN INTERACTED WITH
		Array.prototype.forEach.call(formValidator.formInputs, function (field) {
			const textInputs = field.querySelectorAll(
				"input[type='text'], input[type='email'], input[type='password'], input[type='number'], textarea"
			);
			const clickableInputs = field.querySelectorAll("input[type='radio'], input[type='checkbox'], select");

			Array.prototype.forEach.call(textInputs, function (input) {
				if (!input.hasAttribute('removeErrorOnInputChange')) {
					input.addEventListener('keypress', function (e) {
						formValidator.handleKeyInputs(e, input);
						formValidator.removeErrorOnFormInput(input);
					});

					input.addEventListener('change', function (e) {
						formValidator.removeErrorOnFormInput(input);
					});

					input.setAttribute('error-remove-on-change', true);
				}
			});

			//FOR CLICK INTO INPUTS (SELECTS, RADIOS)
			Array.prototype.forEach.call(clickableInputs, function (input) {
				input.addEventListener('click', function (e) {
					formValidator.removeErrorOnFormInput(input);
				});
			});
		});
	},

	validateOnFormSubmit: function () {
		//LOOP THROUGH FORM & CHECK ERRORS
		formValidator.totalErrors = 0;

		Array.prototype.forEach.call(formValidator.formInputs, function (fieldGroup) {
			formValidator.validateFieldSet(fieldGroup);
		});

		//IF NO ERRORS EXIST, RETURN FORM
		if (formValidator.totalErrors === 0) return true;

		const error = document.querySelector('.field-group--error');
		const rect = error.getBoundingClientRect().top + window.pageYOffset + -140;
		window.scrollTo({ behavior: 'smooth', top: rect });
		return false;
	},

	validateFieldSet: function (field, additionalField) {
		const inputs = field.querySelectorAll('input, textarea, select');

		const inputErrorArray = [];
		Array.prototype.forEach.call(inputs, function (input) {
			const returnedError = formValidator.validateInput(input, additionalField);

			if (returnedError !== undefined) {
				//if error exists in input
				returnedError['field'] = field;
				inputErrorArray.push(returnedError);
			} else {
				input.removeAttribute('aria-invalid'); //sanity removal of red border if no error exists;
			}
		});

		if (inputErrorArray.length > 0) {
			formValidator.prioritiseErrorMessage(inputErrorArray);
			return (formValidator.totalErrors += 1);
		}
	},

	prioritiseErrorMessage: function (inputErrorArray) {
		//checks if field has multiple inputs and if so, prioritises error message
		if (inputErrorArray[0].priority !== undefined) {
			//check if priority scoring exists
			const inputArr = [];
			//ADD EACH UNIQUE INPUT TO AN ARRAY FROM FIELDGROUP
			Array.prototype.forEach.call(inputErrorArray, function (input) {
				if (Array.isArray(input.input)) {
					Array.prototype.forEach.call(input.input, function (item) {
						inputArr.push(item);
					});
				} else {
					if (!inputArr.includes(input.input)) {
						inputArr.push(input.input);
					}
				}
			});
			let topPriority = [];
			Array.prototype.forEach.call(inputErrorArray, function (item) {
				if (!topPriority.length || item.priority < topPriority[0].priority) {
					topPriority = [];
					topPriority.push(item);
				}
			});

			//send highest priority message with the input array
			formValidator.showValidationErrors({
				input: inputArr,
				message: topPriority[0].message,
				field: inputErrorArray[0].field,
			});
		} else {
			formValidator.showValidationErrors(inputErrorArray.pop());
		}
	},

	showValidationErrors: function (field) {
		const fieldInput = field.field;
		const currentFieldErrorContainer = fieldInput.querySelectorAll('div.field-validation-error');
		const currentFieldError = fieldInput.querySelectorAll('div.error-message');

		if (currentFieldError.length > 0 && currentFieldError[0].innerHTML === field.message) {
			//if message is same as one that exists
			return;
		}

		if (currentFieldError.length > 0 && currentFieldError[0].innerHTML !== field.message) {
			//if message is different, remove current error so that highest priority can be set
			currentFieldErrorContainer[0].remove();
		}

		const errorMessageNode = document.createElement('div');
		errorMessageNode.classList.add('error-message');
		errorMessageNode.innerHTML = field.message;
		errorMessageNode.id = formValidator.setErrorBorderColour(field.input);

		//error message container html
		const errorMessageContainer = document.createElement('div');
		errorMessageContainer.classList.add('field-validation-error');
		errorMessageContainer.appendChild(errorMessageNode);

		if (fieldInput.querySelectorAll('label.label--standard').length > 0) {
			//label is main form label
			const label = fieldInput.querySelectorAll('label.label--standard');
			fieldInput.insertBefore(errorMessageContainer, label[0].nextElementSibling);
			fieldInput.classList.add('field-group--error');
		} else {
			const legend = fieldInput.querySelectorAll('legend'); //legend is main form label
			fieldInput.insertBefore(errorMessageContainer, legend[0]);
			fieldInput.classList.add('field-group--error');
		}
	},

	setErrorBorderColour: function (field) {
		let errorId = '';
		if (Array.isArray(field)) {
			Array.prototype.forEach.call(field, function (input) {
				const inputNode = document.querySelector(`#${input}`);
				const splitInput = input.split('_');
				errorId = `${splitInput[0]}-invalid`;
				inputNode.setAttribute('aria-invalid', true);
				inputNode.setAttribute('aria-errormessage', errorId);
			});
		} else {
			const inputNode = document.querySelector(`#${field}`);
			inputNode.setAttribute('aria-invalid', true);
			errorId = `${field}-invalid`;
			inputNode.setAttribute('aria-errormessage', errorId);
		}
		return errorId;
	},

	removeErrorOnFormInput: function (input) {
		if (!input.hasAttribute('aria-invalid')) {
			return;
		}

		input.removeAttribute('aria-invalid');
		const fieldGroup = input.closest('.field-group');
		const message = fieldGroup.querySelectorAll('div.field-validation-error')[0];

		fieldGroup.classList.remove('field-group--error');
		if (message) {
			fieldGroup.removeChild(message);
		}
	},

	removeInputFromFormArray: function (input) {
		const field = input.closest('.field-group');

		if (field && field.classList.contains('field-group')) {
			field.classList.add('field-group-hidden');
			field.classList.remove('field-group');
			field.classList.add('display-none');
		}
		formValidator.formInputs = formValidator.form.querySelectorAll('.field-group');
		return;
	},

	addInputToFormArray: function (input) {
		const field = input.closest('.field-group-hidden');

		if (field && field.classList.contains('field-group-hidden')) {
			field.classList.add('field-group');
			field.classList.remove('field-group-hidden');
			field.classList.remove('display-none');
		}

		formValidator.formInputs = formValidator.form.querySelectorAll('.field-group');
		return;
	},
};

var contactUsForm = {
	form: document.getElementById('cnfsd-contact-form'),
	subjectSelect: document.getElementById('subject_select'),
	issuesSelect: document.getElementById('issues_select'),
	errorSummaryBox: document.querySelector('.mod-error-summary'),
	loginLink: document.getElementById('Login_Link'),
	chosenSubjectOption: null,
	chosenIssuesOption: null,
	questionAnswerContainer: document.getElementById('cfq-question-toggle'),
	contactDetailsForm: document.getElementById('cfq-contact-details-form'),
	contactSucessCard: document.getElementById('cfq-contact-success'),
	button: document.getElementById('cfq0-continue'),
	showContactDetails: document.getElementById('q0-yes'),
	hideContactDetails: document.getElementById('q0-no'),
	validateForm: null,
	jsonData: null,

	initialise: function () {
		const jsonData = document.getElementById('cnfsd-contact-jsonData').textContent;
		contactUsForm.jsonData = JSON.parse(jsonData);

		contactUsForm.form.addEventListener('submit', function (e) {
			e.preventDefault();
			const valid = formValidator.validateOnFormSubmit();
			contactUsForm.updateDataLayer(valid);
			contactUsForm.submitForm(valid);

			return;
		});

		formValidator.initialise();

		contactUsForm.populateSubjectSelectOptions();

		contactUsForm.subjectSelect.onchange = function (e) {
			contactUsForm.populateIssuesSelectOptions(e.target);
		};

		contactUsForm.issuesSelect.onclick = function (e) {
			contactUsForm.checkSelectChecked();
		};

		contactUsForm.issuesSelect.onchange = function (e) {
			contactUsForm.populateQuestionAnswerBox(e.target);
		};

		contactUsForm.button.onclick = function (e) {
			e.preventDefault();
			contactUsForm.toggleQuestionAnswerContainer();
		};

		contactUsForm.hideContactDetails.onchange = function (e) {
			contactUsForm.toggleContactDetails(e.target.value);
		};

		contactUsForm.showContactDetails.onchange = function (e) {
			contactUsForm.toggleContactDetails(e.target.value);
		};

		contactUsForm.checkIfPhoneDesired();

		if (contactUsForm.loginLink) {
			contactUsForm.loginLink.addEventListener('click', function (e) {
				e.stopPropagation();
				e.preventDefault();
				contactUsForm.createLoginLink();
			});
		}

		const hasQueryString = window.location.href.indexOf('?product');

		window.addEventListener('load', function (e) {
			//PREVENT DEFAULT BROWSER FUNCTIONALITY SETTING SELECTS TO PREVIOUS VALUES;
			if (hasQueryString !== -1) {
				return;
			}
			if (contactUsForm.subjectSelect.selectedIndex === 0) {
				return;
			}

			contactUsForm.populateIssuesSelectOptions(contactUsForm.subjectSelect);
			contactUsForm.removeIssuesSelectOptions();
			contactUsForm.showContactDetails.checked = false;
			contactUsForm.hideContactDetails.checked = false;
		});

		if (hasQueryString) {
			contactUsForm.parseQueryString(hasQueryString);
		}
	},

	parseQueryString: function (substringIndex) {
		const queryString = window.location.href.substring(substringIndex + 1),
			selectQueryStrings = queryString.split('&');

		//CONVERT CODED QUERY STRING TO NORMAL TEXT
		Array.prototype.forEach.call(selectQueryStrings, function (item) {
			const selectValue = item.substring(item.indexOf('=') + 1),
				parsedValue = selectValue.split('%27').join("'").split('%20').join(' ');

			if (item.includes('product')) {
				contactUsForm.setSelectAsQuerystringValue(
					contactUsForm.subjectSelect,
					parsedValue,
					contactUsForm.populateIssuesSelectOptions
				);
			}

			if (item.includes('moreInformation') && contactUsForm.chosenSubjectOption) {
				contactUsForm.setSelectAsQuerystringValue(
					contactUsForm.issuesSelect,
					parsedValue,
					contactUsForm.populateQuestionAnswerBox
				);
			}
		});
	},

	setSelectAsQuerystringValue: function (input, value, callback) {
		//SETS VALUE OF SELECT TO QUERY STRING
		const options = input.querySelectorAll('option');

		Array.prototype.forEach.call(options, function (option, i) {
			if (option.value.toLowerCase() === value.toLowerCase()) {
				input.selectedIndex = i;
				callback(input);
			}
		});
	},

	createLoginLink: function () {
		//GETS CURRENT LOGIN LINK AND APPENDS SUBJECT/ISSUE SELECT VALUES
		let loginLink = contactUsForm.loginLink.href;
		loginLink += `%3Fproduct%3D${contactUsForm.subjectSelect.value}`;

		if (contactUsForm.issuesSelect.value) {
			loginLink += `%26moreInformation%3D${contactUsForm.issuesSelect.value}`;
		}

		window.location.href = loginLink;
	},

	updateDataLayer: function (formSuccess) {
		//GET ALL ERRORS IN FORM
		const erroredInputs = contactUsForm.form.querySelectorAll(
			"input[aria-invalid='true'], textarea[aria-invalid='true']"
		);

		//CREATE ARRAY OF ERROR NAMES
		const eventErrors = [];
		Array.prototype.forEach.call(erroredInputs, function (input) {
			eventErrors.push(input.name);
		});

		//REMOVE REPEATED ERRORS (BASICALLY A SET/ SPREAD)
		const errorArray = [];
		Array.prototype.forEach.call(eventErrors, function (item) {
			if (!errorArray.includes(item)) {
				errorArray.push(item);
			}
		});

		//PUSH TO DATA LAYER
		dataLayer.push({
			event: 'Contact Us',
			eventSuccess: formSuccess,
			eventErrors: errorArray,
		});
	},

	populateSubjectSelectOptions: function () {
		//CREATE SELECT OPTIONS FROM THE JASON FOUND IN SCRIPT TAG
		Array.prototype.forEach.call(contactUsForm.jsonData, function (item) {
			const option = document.createElement('option');
			option.innerHTML = item.subject;
			option.value = item.subjectValue;
			contactUsForm.subjectSelect.appendChild(option);
		});
	},

	resetUIOnInputChange: () => {
		contactUsForm.chosenIssuesOption = null;
		contactUsForm.questionAnswerContainer.classList.add('display-none');
		contactUsForm.contactDetailsForm.classList.add('display-none');
		contactUsForm.showContactDetails.checked = false;
		contactUsForm.hideContactDetails.checked = false;
		contactUsForm.contactSucessCard.classList.add('display-none');
		contactUsForm.button.classList.remove('display-none');
	},

	populateIssuesSelectOptions: function (option) {
		//RESET UI
		contactUsForm.resetUIOnInputChange();

		//CREATES ISSUE SELECT OPTIONS FROM THE USER'S CHOSEN SELECT
		Array.prototype.forEach.call(contactUsForm.jsonData, function (item) {
			if (item.subjectValue === option.value) {
				contactUsForm.chosenSubjectOption = item;

				//CHECKS IF THE SUBJECT IS AN INSURANCE TYPE
				contactUsForm.checkIfInsuranceOption(item.isInsuranceProduct);
			}
		});

		//CALLS FUNCTION TO REMOVE PREVIOUSLY ADDED OPTIONS
		if (contactUsForm.issuesSelect.childElementCount > 1) {
			contactUsForm.removeIssuesSelectOptions();
		} else {
			contactUsForm.createIssuesSelectedOptions();
		}
	},

	checkIfPhoneDesired: function () {
		const phoneInput = document.getElementById('phone_number'),
			phoneField = phoneInput.closest('fieldset.field-group'),
			phoneConsentYes = document.getElementById('phone_consent-yes'),
			phoneConsentNo = document.getElementById('phone_consent-no');

		//REMOVE PHONE INPUT BY DEFAULT
		formValidator.removeInputFromFormArray(phoneField);

		//ADD EVENT LISTENERS ON PHONE CONSENT RADIO BUTTONS TO ADD/REMOVE PHONE INPUT
		phoneConsentYes.addEventListener('change', function (e) {
			formValidator.addInputToFormArray(phoneField);
		});

		phoneConsentNo.addEventListener('change', function (e) {
			formValidator.removeInputFromFormArray(phoneField);
		});
	},

	checkIfInsuranceOption: function (isAnInsuranceProduct) {
		//CHECKS IF THE OPTION SPECIFIED IS AN INSURANCE OPTION
		const insuranceRadioSection = document.getElementById('insurance_bought-group');
		const insuranceRadio = insuranceRadioSection.querySelector('input');

		//IF IT ISN'T AN INSURANCE PRODUCT, CALLS FUNCTION TO REMOVE/ ADD QUESTION FROM/ TO FORM
		if (isAnInsuranceProduct) {
			formValidator.addInputToFormArray(insuranceRadio);
		} else {
			const currentlyCheckedInput = document.querySelector("input[name='insurance_bought']:checked");
			if (currentlyCheckedInput !== null) {
				currentlyCheckedInput.checked = false;
			}
			formValidator.removeInputFromFormArray(insuranceRadio);
		}
	},

	removeIssuesSelectOptions: function () {
		//REMOVES PREVIOUSLY ADDED OPTIONS
		while (contactUsForm.issuesSelect.childNodes.length > 1) {
			contactUsForm.issuesSelect.removeChild(contactUsForm.issuesSelect.lastChild);
		}

		//RECREATES THE DEFAULT SELECT AN ISSUE OPTION
		const option = document.createElement('option');
		option.innerHTML = 'Select an issue';
		option.selected = option.disabled = option.hidden = true;
		contactUsForm.issuesSelect.appendChild(option);

		//CALLS FUNCTION TO REPOPULATE THE CHOSEN
		contactUsForm.createIssuesSelectedOptions();
	},

	createIssuesSelectedOptions: function () {
		//IF THERE IS ONLY ONE DEFAULT QUESTION FOR THE CHOSEN SUBJECT
		if (contactUsForm.chosenSubjectOption.issues.length === 1) {
			formValidator.removeInputFromFormArray(contactUsForm.issuesSelect);

			//CREATES OPTION
			const option = document.createElement('option');
			option.innerHTML = contactUsForm.chosenSubjectOption.issues[0].question;
			option.value = contactUsForm.chosenSubjectOption.issues[0].questionValue;

			//SETS VALUE OF ISSUES SELECT AS OPTION
			contactUsForm.issuesSelect.appendChild(option);
			contactUsForm.issuesSelect.value = contactUsForm.chosenSubjectOption.issues[0].questionValue;

			//CALLS FUNCTION TO USE ANSWER FOR ANSWER BOX
			contactUsForm.populateQuestionAnswerBox(option);
			return;
		}

		//IF THERE IS MORE THAN ONE OPTION AVAILABLE:

		formValidator.addInputToFormArray(contactUsForm.issuesSelect);

		//CREATE THE OPTIONS FOR ISSUES SELECT BASED ON THE CHOSEN SUBJECT
		Array.prototype.forEach.call(contactUsForm.chosenSubjectOption.issues, function (item) {
			const option = document.createElement('option');
			option.innerHTML = item.question;
			option.value = item.questionValue;
			contactUsForm.issuesSelect.appendChild(option);
		});
	},

	populateQuestionAnswerBox: function (option) {
		const questionAnswerBox = document.querySelector('.mod-info-box');
		const span = questionAnswerBox.querySelector('span');
		const p = questionAnswerBox.querySelector('p');

		Array.prototype.forEach.call(contactUsForm.chosenSubjectOption.issues, function (item) {
			if (item.questionValue === option.value) {
				contactUsForm.chosenIssuesOption = item;
			}
		});

		//POPULATE THE QUESTION/ ANSWER BOX
		if (contactUsForm.chosenIssuesOption) {
			span.innerHTML = contactUsForm.chosenIssuesOption.question;
			p.innerHTML = contactUsForm.chosenIssuesOption.answer;
		}

		//HANDLES CHANGES IN SELECT OPTIONS
		if (contactUsForm.button.classList.contains('display-none')) {
			if (option.value === 'other') {
				contactUsForm.questionAnswerContainer.classList.add('display-none');
				contactUsForm.contactDetailsForm.classList.remove('display-none');
				return;
			}

			contactUsForm.questionAnswerContainer.classList.remove('display-none');

			if (!contactUsForm.showContactDetails.checked) {
				contactUsForm.contactDetailsForm.classList.add('display-none');
			}
		}
	},

	toggleQuestionAnswerContainer: function () {
		//CONTINUE BUTTON PRESSED
		const selectFieldGroup = contactUsForm.subjectSelect.closest('.field-group');
		const issuesFieldGroup = contactUsForm.issuesSelect.closest('.field-group');

		//IF NO SUBJECT SELECTED, CALL FUNCTION TO THROW ERROR
		if (!contactUsForm.chosenSubjectOption) {
			return [formValidator.validateFieldSet(selectFieldGroup), formValidator.validateFieldSet(issuesFieldGroup)];
		}

		//IF NO ISSUE SELECTED, CALL FUNCTION TO THROW ERROR
		if (!contactUsForm.chosenIssuesOption) {
			return formValidator.validateFieldSet(issuesFieldGroup);
		}

		//IF USER CHOOSES OTHER FROM ISSUES SELECT INPUT FOR THAT SUBJECT
		if (issuesFieldGroup && contactUsForm.issuesSelect.value === 'other') {
			contactUsForm.questionAnswerContainer.classList.add('display-none');
			contactUsForm.button.classList.add('display-none');
			contactUsForm.contactDetailsForm.classList.remove('display-none');
			return;
		}

		//ELSE SHOW THE ANSWER BOX
		contactUsForm.questionAnswerContainer.classList.remove('display-none');
		contactUsForm.button.classList.add('display-none');
	},

	checkSelectChecked: function () {
		//ERROR IF SELECT INPUT HAS NO VALUE
		if (!contactUsForm.chosenSubjectOption) {
			const fieldGroup = contactUsForm.subjectSelect.closest('.field-group');
			return formValidator.validateFieldSet(fieldGroup, true);
		}
		return;
	},

	toggleContactDetails: function (input) {
		//SHOW/HIDE CONTACT DETAILS FORM
		if (input === 'Yes') {
			contactUsForm.contactDetailsForm.classList.remove('display-none');
			contactUsForm.contactSucessCard.classList.add('display-none');
			return;
		}

		if (input === 'No') {
			contactUsForm.contactDetailsForm.classList.add('display-none');
			contactUsForm.contactSucessCard.classList.remove('display-none');
			return;
		}
	},

	submitForm: function (formValid) {
		//IF FORM IS VALID, SUBMIT THE INPUTS VALUES
		const dobDay = document.getElementById('dob_day').value;
		const dobMon = document.getElementById('dob_month').value;
		const dobYear = document.getElementById('dob_year').value;

		const formInformation = {
			product: document.getElementById('subject_select').value,
			MoreInformation: document.getElementById('issues_select').value,
			Title: null,
			FirstName: document.getElementById('first_name').value,
			Surname: document.getElementById('last_name').value,
			EmailAddress: document.getElementById('email_address').value,
			Postcode: document.getElementById('postcode').value,
			DateOfBirth: `${dobDay}/${dobMon}/${dobYear}`,
			ContactPhoneQuestion: document.querySelector("input[name='phone_consent']:checked").value,
			ContactPhoneNumber: document.getElementById('phone_number').value,
			HasPurchased: document.querySelector("input[name='insurance_bought']:checked")
				? document.querySelector("input[name='insurance_bought']:checked").value
				: '1',
			QuerySubject: document.getElementById('message_subject').value,
			QueryDescription: document.getElementById('message_content').value,
			HttpAttributeReturnUrl: document.getElementById('returnUrl')
				? document.getElementById('returnUrl').value
				: '',
			HttpAttributePriority: document.getElementById('HttpAttributePriority')
				? document.getElementById('HttpAttributePriority').value
				: '',
			HttpAttributeOrigin: document.getElementById('HttpAttributeOrigin')
				? document.getElementById('HttpAttributeOrigin').value
				: '',
			HttpAttributeExternal: document.getElementById('HttpAttributeExternal')
				? document.getElementById('HttpAttributeExternal').value
				: '',
		};

		const xhr = new XMLHttpRequest();
		xhr.open('POST', '/api/contact-us/self-serve-submit');

		xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

		xhr.onload = function () {
			if (xhr.readyState === xhr.DONE) {
				if (xhr.status === 200) {
					const response = JSON.parse(xhr.response);
					if (response.Success) {
						const returnUrl = document.getElementById('returnUrl').value;
						window.location.href = returnUrl;
					} else {
						contactUsForm.errorSummaryBox.classList.remove('display-none');
						var rect =
							contactUsForm.errorSummaryBox.getBoundingClientRect().top + window.pageYOffset + -140;
						window.scrollTo({ behavior: 'smooth', top: rect });
					}
				} else {
					contactUsForm.errorSummaryBox.classList.remove('display-none');
					var rect = contactUsForm.errorSummaryBox.getBoundingClientRect().top + window.pageYOffset + -140;
					window.scrollTo({ behavior: 'smooth', top: rect });
				}
			}
		};
		xhr.send(JSON.stringify(formInformation));
	},
};

document.addEventListener('DOMContentLoaded', function () {
	if (formValidator.form) {
		//HIDE UI IF CMS WORK NOT DONE
		if (!contactUsForm.questionAnswerContainer.classList.contains('display-none'))
			contactUsForm.questionAnswerContainer.classList.add('display-none');
		if (!contactUsForm.contactDetailsForm.classList.contains('display-none'))
			contactUsForm.contactDetailsForm.classList.add('display-none');
		if (!contactUsForm.contactSucessCard.classList.contains('display-none'))
			contactUsForm.contactSucessCard.classList.add('display-none');

		contactUsForm.initialise();
	}
});
