const stickyCta = (function () {
	const stickyCtaEl = document.querySelector('.mod-sticky-cta');

	function handleStickyCtaScroll(addClass) {
		if (addClass) {
			stickyCtaEl.classList.add('is-shown');
		} else {
			stickyCtaEl.classList.remove('is-shown');
		}
	}

	window.addEventListener('scroll', function () {
		if (stickyCtaEl === null) {
			return;
		}
		if (window.scrollY >= 200) {
			handleStickyCtaScroll(true);
		} else {
			handleStickyCtaScroll(false);
		}
	});
})();
