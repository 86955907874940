var interactiveRadioButtons = {
	interactiveSections: document.querySelectorAll('.mod-interactive-radio-buttons'),

	init: function () {
		Array.prototype.forEach.call(interactiveRadioButtons.interactiveSections, function (interactiveArea) {
			const answers = interactiveArea.querySelector('.mod-interactive-radio-buttons__answer'),
				questions = interactiveArea.querySelector('.mod-interactive-radio-buttons__question'),
				radioInputs = interactiveArea.querySelectorAll('input[type="radio"]'),
				button = interactiveArea.querySelector('a.btn');

			//HIDE THE ANSWERS ON LOAD
			answers.style.display = 'none';

			//ADD EVENT LISTENER TO BUTTON TO SHOW ANSWER
			button.addEventListener('click', function (e) {
				e.preventDefault();
				if (!interactiveArea.querySelector('input[type="radio"]:checked')) {
					return interactiveRadioButtons.showError(radioInputs[0]);
				}
				return interactiveRadioButtons.showResults(interactiveArea);
			});

			//ADD EVENT LISTENER TO REMOVE ERROR (IF EXISTS), WHEN INPUT CLICKED
			Array.prototype.forEach.call(radioInputs, function (input) {
				input.addEventListener('click', function (e) {
					interactiveRadioButtons.removeError(interactiveArea);
				});
			});
		});
	},

	removeError: function (modInteractiveRadioContainer) {
		const fieldset = modInteractiveRadioContainer.querySelector('fieldset.field-group');
		const validationError = modInteractiveRadioContainer.querySelector('.field-validation-error');

		if (fieldset) {
			fieldset.classList.remove('field-group--error');
			validationError.remove();
		}
		return;
	},

	showError: function (topRadio) {
		//RECEIVES FIRST RADIO BUTTON FOR ERROR POSITION
		const radioContainer = topRadio.parentElement;
		const fieldGroup = radioContainer.closest('fieldset');

		if (fieldGroup.classList.contains('field-group--error')) {
			return;
		}

		fieldGroup.classList.add('field-group--error');
		const errorContainer = document.createElement('div');
		errorContainer.classList.add('field-validation-error');
		const errorMessage = document.createElement('div');
		errorMessage.innerHTML = 'Choose an answer';
		errorContainer.appendChild(errorMessage);

		radioContainer.parentElement.insertBefore(errorContainer, radioContainer);
	},

	showResults: function (modInteractiveRadioContainer) {
		const question = modInteractiveRadioContainer.querySelector('.mod-interactive-radio-buttons__question');
		const answer = modInteractiveRadioContainer.querySelector('.mod-interactive-radio-buttons__answer');

		question.style.display = 'none';
		answer.style.display = 'block';
	},
};

document.addEventListener('DOMContentLoaded', function () {
	//IF THE INTERACTIVE RADIO BUTTONS EXIST ON THE PAGE
	if (interactiveRadioButtons.interactiveSections.length) {
		interactiveRadioButtons.init();
	}
});
