const tableScroll = (function () {
	function mobileTableScroll() {
		const scrollTable = document.querySelector('.mod-table--scroll');
		if (!scrollTable) {
			return;
		}
		const scrollTableParent = scrollTable.parentElement;
		scrollTableParent.closest('.mod-flex').firstElementChild.style.minWidth = '0';
	}

	return {
		mobileTableScroll: mobileTableScroll,
	};
})();

window.addEventListener('load', tableScroll.mobileTableScroll);
